"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdaptyEventEmitter = void 0;
const react_native_1 = require("react-native");
class AdaptyEventEmitter {
    #nativeEmitter;
    #listeners;
    constructor() {
        this.#nativeEmitter = new react_native_1.NativeEventEmitter(react_native_1.NativeModules.RNAdapty);
        this.#listeners = [];
    }
    /**
     * Adapty Event Handler
     *
     * @param type defines which event you are listening to
     * @param callback defines what action would be called, when event fired
     */
    addListener(event, callback) {
        const parseCallback = (...data) => {
            const args = data.map(arg => JSON.parse(arg));
            callback(...args);
        };
        const subscription = this.#nativeEmitter.addListener(event, parseCallback);
        this.#listeners.push(subscription);
        return subscription;
    }
    addEventListener = this.addListener;
    removeAllListeners() {
        this.#listeners.forEach(listener => listener.remove());
        this.#listeners = [];
    }
}
exports.AdaptyEventEmitter = AdaptyEventEmitter;
