"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Purchases = void 0;
const react_native_1 = require("react-native");
const error_1 = require("./error");
class Purchases {
    #ctx;
    constructor(context) {
        this.#ctx = context;
    }
    /**
     * Use to restore purchases on a new device.
     * Purchases will appear in user's purchasesInfo
     *
     * @throws {@link AdaptyError}
     */
    async restore() {
        (0, error_1.isSdkAuthorized)(this.#ctx.isActivated);
        try {
            const json = await this.#ctx.module.restorePurchases();
            const result = JSON.parse(json);
            return result;
        }
        catch (error) {
            throw (0, error_1.attemptToDecodeError)(error);
        }
    }
    /**
     * Updates any available fields to a current user
     *
     * @returns Promised
     * @throws {@link AdaptyError}
     */
    async getInfo(options = {}) {
        (0, error_1.isSdkAuthorized)(this.#ctx.isActivated);
        try {
            const json = await this.#ctx.module.getPurchaseInfo(options);
            const result = JSON.parse(json);
            return result;
        }
        catch (error) {
            throw (0, error_1.attemptToDecodeError)(error);
        }
    }
    /**
     * @throws AdaptyError
     */
    async makePurchase(product, params) {
        (0, error_1.isSdkAuthorized)(this.#ctx.isActivated);
        try {
            const json = await this.#ctx.module.makePurchase(product.vendorProductId, product.variationId, react_native_1.Platform.select({
                ios: params?.ios?.offerId,
                macos: params?.ios?.offerId,
                android: params?.android?.subscriptionUpdateParam,
            }));
            const result = JSON.parse(json || '');
            return result;
        }
        catch (error) {
            throw (0, error_1.attemptToDecodeError)(error);
        }
    }
    /**
     * Associates transaction with a certain paywall
     */
    async setVariationId(variationId, transactionId) {
        try {
            await this.#ctx.module.setVariationID(variationId, transactionId);
        }
        catch (error) {
            throw (0, error_1.attemptToDecodeError)(error);
        }
    }
}
exports.Purchases = Purchases;
