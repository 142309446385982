"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdaptyEvent = void 0;
var AdaptyEvent;
(function (AdaptyEvent) {
    AdaptyEvent["OnPurchaseSuccess"] = "onPurchaseSuccess";
    AdaptyEvent["OnPurchaseFailed"] = "onPurchaseFailed";
    AdaptyEvent["OnInfoUpdate"] = "onInfoUpdate";
    AdaptyEvent["OnPromoReceived"] = "onPromoReceived";
})(AdaptyEvent = exports.AdaptyEvent || (exports.AdaptyEvent = {}));
