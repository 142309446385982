"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.snakeToCamel = exports.extractModule = void 0;
const react_native_1 = require("react-native");
function extractModule() {
    return react_native_1.NativeModules.RNAdapty;
}
exports.extractModule = extractModule;
function snakeToCamel(target) {
    if (Array.isArray(target)) {
        return target.map(element => {
            if (typeof element === 'string') {
                return element;
            }
            return snakeToCamel(element);
        });
    }
    if (typeof target === 'object') {
        const obj = {};
        for (const key in target) {
            if (!target.hasOwnProperty(key)) {
                continue;
            }
            if (typeof target[key] === 'object') {
                obj[snakeToCamel(key)] = snakeToCamel(target[key]);
            }
            else {
                obj[snakeToCamel(key)] = target[key];
            }
        }
        return obj;
    }
    if (typeof target === 'string') {
        return target.replace(/(\_\w)/g, function (m) {
            return m[1].toUpperCase();
        });
    }
    return target;
}
exports.snakeToCamel = snakeToCamel;
