"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.activateAdapty = void 0;
const adapty_1 = require("./adapty");
const sdk_1 = require("./sdk");
const error_1 = require("./sdk/error");
const utils_1 = require("./utils");
/**
 *
 * This function makes it possible
 * to use Adapty SDK, consider placing it
 * inside a core `useEffect` or a core `didComponentMount`
 *
 * @example
 * useEffect(() => {
 *   activateAdapty({
 *     sdkKey: "Public_sdk_key",
 *     customerUserId: myUser.id
 *   });
 * },[myUser.id]);
 *
 *
 * This function should be called before using an SDK.
 * For more information about arguments look for an @interface AdaptyActivateArgs
 */
async function activateAdapty(props) {
    const module = (0, utils_1.extractModule)();
    const userId = props.customerUserId?.toString() || null;
    const DEFAULT_LOG_LEVEL = 'none';
    const logLevel = props.logLevel || DEFAULT_LOG_LEVEL;
    const sdkKey = props.sdkKey;
    if (!sdkKey) {
        throw new error_1.AdaptyError({
            adaptyCode: 'sdkNotActive',
            code: 400,
            localizedDescription: 'SDK private key was not passed',
        });
    }
    const DEFAULT_OBSERVER_MODE = false;
    const isModeObserver = props.observerMode !== undefined
        ? props.observerMode
        : DEFAULT_OBSERVER_MODE;
    try {
        await module.activate(sdkKey, userId, isModeObserver, logLevel);
        await sdk_1.Adapty.activateSdk(adapty_1.adapty, sdkKey);
    }
    catch (error) {
        throw error;
    }
}
exports.activateAdapty = activateAdapty;
