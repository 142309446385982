"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Adapty = void 0;
const utils_1 = require("../utils");
const error_1 = require("./error");
const events_1 = require("./events");
const paywall_1 = require("./paywall");
const profile_1 = require("./profile");
const promo_1 = require("./promo");
const purchases_1 = require("./purchases");
class Adapty extends events_1.AdaptyEventEmitter {
    #ctx;
    profile;
    purchases;
    promo;
    paywalls;
    constructor() {
        super();
        this.#ctx = {
            module: (0, utils_1.extractModule)(),
            isActivated: false,
            sdkKey: undefined,
            observerMode: false,
            customerUserId: undefined,
        };
        this.profile = new profile_1.Profile(this.#ctx);
        this.purchases = new purchases_1.Purchases(this.#ctx);
        this.promo = new promo_1.Promo(this.#ctx);
        this.paywalls = new paywall_1.AdaptyPaywalls(this.#ctx);
    }
    /**
     * @static @private
     * It is being used to define wether
     * a user has activated the SDK using
     * @function activateAdapty or @function useAdapty
     */
    static activateSdk(consumer, sdkKey) {
        consumer.#ctx.isActivated = true;
        consumer.#ctx.sdkKey = sdkKey;
    }
    async getApnsToken() {
        try {
            return this.#ctx.module.getAPNSToken();
        }
        catch (error) {
            throw (0, error_1.attemptToDecodeError)(error);
        }
    }
    async setApnsToken(token) {
        try {
            this.#ctx.module.setAPNSToken(token);
        }
        catch (error) {
            throw (0, error_1.attemptToDecodeError)(error);
        }
    }
    async setIsExternalAnalyticsEnabled(isEnabled) {
        try {
            this.#ctx.module.setExternalAnalyticsEnabled(isEnabled);
        }
        catch (error) {
            throw (0, error_1.attemptToDecodeError)(error);
        }
    }
    /**
     * Updates
     *
     * @throws AdaptyError
     */
    async updateAttribution(networkUserId, attribution, source) {
        (0, error_1.isSdkAuthorized)(this.#ctx.isActivated);
        try {
            return this.#ctx.module.updateAttribution(attribution, source, networkUserId);
        }
        catch (error) {
            throw (0, error_1.attemptToDecodeError)(error);
        }
    }
}
exports.Adapty = Adapty;
