"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Profile = void 0;
const error_1 = require("./error");
class Profile {
    #ctx;
    constructor(context) {
        this.#ctx = context;
    }
    /**
     * Use identify to set userID to a current session
     *
     * @returns Promised
     * @throws AdaptyError
     */
    async identify(customerUserId) {
        (0, error_1.isSdkAuthorized)(this.#ctx.isActivated);
        try {
            const result = await this.#ctx.module.identify(customerUserId);
            return result;
        }
        catch (error) {
            throw (0, error_1.attemptToDecodeError)(error);
        }
    }
    /**
     * Updates any available fields to a current user
     * @returns Promised
     * @throws AdaptyError
     */
    async update(updatedFields) {
        (0, error_1.isSdkAuthorized)(this.#ctx.isActivated);
        try {
            if (updatedFields.hasOwnProperty('birthday')) {
                updatedFields.birthday = updatedFields.birthday?.toISOString();
            }
            const result = await this.#ctx.module.updateProfile(updatedFields);
            return result;
        }
        catch (error) {
            throw (0, error_1.attemptToDecodeError)(error);
        }
    }
    /**
     * Drops a user connected to the session
     *
     * @throws AdaptyError
     */
    async logout() {
        (0, error_1.isSdkAuthorized)(this.#ctx.isActivated);
        try {
            const result = await this.#ctx.module.logout();
            return result;
        }
        catch (error) {
            throw (0, error_1.attemptToDecodeError)(error);
        }
    }
}
exports.Profile = Profile;
