"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Promo = void 0;
const react_native_1 = require("react-native");
const error_1 = require("./error");
class Promo {
    #ctx;
    constructor(context) {
        this.#ctx = context;
    }
    /**
     * @description Returns true if a sheet that enables users
     * to redeem subscription offer codes can be displayed.
     */
    canPresentCodeRedemptionSheet() {
        return react_native_1.Platform.OS === "ios" && parseInt(react_native_1.Platform.Version, 10) >= 14;
    }
    /**
     * @description Displays a sheet that enables users
     * to redeem subscription offer codes that you generated in App Store Connect.
     */
    presentCodeRedemptionSheet() {
        if (react_native_1.Platform.OS !== 'ios') {
            throw new error_1.AdaptyError({
                adaptyCode: 'badRequest',
                localizedDescription: 'presentCodeRedemptionSheet is an iOS-only method',
                code: 400,
            });
        }
        (0, error_1.isSdkAuthorized)(this.#ctx.isActivated);
        try {
            this.#ctx.module.presentCodeRedemptionSheet();
        }
        catch (error) {
            throw (0, error_1.attemptToDecodeError)(error);
        }
    }
    async getPromo() {
        (0, error_1.isSdkAuthorized)(this.#ctx.isActivated);
        try {
            const json = await this.#ctx.module.getPromo();
            const result = JSON.parse(json);
            return result;
        }
        catch (error) {
            throw (0, error_1.attemptToDecodeError)(error);
        }
    }
}
exports.Promo = Promo;
