"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdaptyPaywalls = void 0;
const error_1 = require("./error");
class AdaptyPaywalls {
    #ctx;
    constructor(context) {
        this.#ctx = context;
    }
    async setFallback(paywalls) {
        try {
            await this.#ctx.module.setFallbackPaywalls(paywalls);
            return;
        }
        catch (error) {
            throw (0, error_1.attemptToDecodeError)(error);
        }
    }
    async logShow(variationId) {
        try {
            await this.#ctx.module.logShowPaywall(variationId);
            return;
        }
        catch (error) {
            throw (0, error_1.attemptToDecodeError)(error);
        }
    }
    /**
     * @returns
     * Object of
     */
    async getPaywalls(options = {}) {
        (0, error_1.isSdkAuthorized)(this.#ctx.isActivated);
        try {
            const json = await this.#ctx.module.getPaywalls(options);
            const result = JSON.parse(json);
            // console.log('\n-------------------\nPAYWALLS RESULT:');
            // result.paywalls.forEach((paywall) => console.log(Object.keys(paywall), paywall.developerId));
            // result.products.forEach((product) => console.log(Object.keys(product), product.vendorProductId));
            // console.log('\n-------------------\nPAYWALLS END!');
            return result;
        }
        catch (error) {
            throw (0, error_1.attemptToDecodeError)(error);
        }
    }
}
exports.AdaptyPaywalls = AdaptyPaywalls;
