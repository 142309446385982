"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSdkAuthorized = exports.attemptToDecodeError = exports.AdaptyError = void 0;
class AdaptyError {
    adaptyCode;
    code;
    localizedDescription;
    constructor({ adaptyCode, localizedDescription, code, }) {
        this.adaptyCode = adaptyCode;
        this.localizedDescription = localizedDescription || 'Unknown Adapty Error';
        this.code = code;
    }
}
exports.AdaptyError = AdaptyError;
function attemptToDecodeError(error) {
    if (typeof error !== 'object' || !error?.hasOwnProperty('message')) {
        console.warn('[Adapty RN SDK]: Failed to parse Adapty error\n');
        return new AdaptyError({
            adaptyCode: 'unknown',
            code: 400,
            localizedDescription: JSON.stringify(error),
        });
    }
    const message = JSON.parse(error.message);
    if (message) {
        return new AdaptyError({
            adaptyCode: mapAdaptyErrorCode(message.adaptyCode),
            code: message.code,
            localizedDescription: message.localizedDescription,
        });
    }
    console.warn('[Adapty RN SDK]: Failed to parse Adapty error\n');
    return new AdaptyError({
        adaptyCode: 'unknown',
        code: 400,
        localizedDescription: undefined,
    });
}
exports.attemptToDecodeError = attemptToDecodeError;
/** @throws AdaptyError */
function isSdkAuthorized(isAuthorized) {
    if (!isAuthorized) {
        throw new AdaptyError({
            adaptyCode: 'sdkNotActive',
            code: 403,
            localizedDescription: 'Adapty SDK was not initialized',
        });
    }
}
exports.isSdkAuthorized = isSdkAuthorized;
function mapAdaptyErrorCode(code) {
    switch (code) {
        case -1:
            return 'none';
        case 0:
            return 'unknown';
        case 1:
            return 'clientInvalid';
        case 2:
            return 'paymentCancelled';
        case 3:
            return 'paymentInvalid';
        case 4:
            return 'paymentNotAllowed';
        case 5:
            return 'storeProductNotAvailable';
        case 6:
            return 'cloudServicePermissionDenied';
        case 7:
            return 'cloudServiceNetworkConnectionFailed';
        case 8:
            return 'cloudServiceRevoked';
        case 9:
            return 'privacyAcknowledgementRequired';
        case 10:
            return 'unauthorizedRequestData';
        case 11:
            return 'invalidOfferIdentifier';
        case 12:
            return 'invalidSignature';
        case 13:
            return 'missingOfferParams';
        case 14:
            return 'invalidOfferPrice';
        case 20:
            return 'adaptyNotInitialized';
        case 21:
            return 'paywallNotFound';
        case 22:
            return 'productNotFound';
        case 23:
            return 'invalidJson';
        case 24:
            return 'currentSubscriptionToUpdateNotFoundInHistory';
        case 25:
            return 'pendingPurchase';
        case 97:
            return 'billingServiceTimeout';
        case 98:
            return 'featureNotSupported';
        case 99:
            return 'billingServiceDisconnected';
        case 102:
            return 'billingServiceUnavailable';
        case 103:
            return 'billingUnavailable';
        case 105:
            return 'developerError';
        case 106:
            return 'billingError';
        case 107:
            return 'itemAlreadyOwned';
        case 108:
            return 'itemNotOwned';
        case 1000:
            return 'noProductIDsFound';
        case 1001:
            return 'noProductsFound';
        case 1002:
            return 'productRequestFailed';
        case 1003:
            return 'cantMakePayments';
        case 1004:
            return 'noPurchasesToRestore';
        case 1005:
            return 'cantReadReceipt';
        case 1006:
            return 'productPurchaseFailed';
        case 1007:
            return 'missingOfferSigningParams';
        case 1008:
            return 'fallbackPaywallsNotRequired';
        case 2000:
            return 'emptyResponse';
        case 2001:
            return 'emptyData';
        case 2002:
            return 'authenticationError';
        case 2003:
            return 'badRequest';
        case 2004:
            return 'outdated';
        case 2006:
            return 'failed';
        case 2007:
            return 'missingParam';
        case 2008:
            return 'invalidProperty';
        case 2009:
            return 'encodingFailed';
        case 2010:
            return 'missingURL';
        default:
            console.warn(`[Adapty RN SDK]: unknown error code: ${code}.\n`);
            return 'unknown';
    }
}
